import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PostWrapper from "../../components/postWrapper"
import PostList from "../../components/postList"
import PostSidebar from "../../components/postSidebarFilter"
import Pagination from "../../components/pagination"

export const query = graphql`
  query($skip: Int, $limit: Int) {
    allSanityCaseStudy(
      sort: { fields: publishDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          category {
            title
            slug {
              current
            }
          }
          _rawThumbnail(resolveReferences: { maxDepth: 10 })
        }
      }
    }
    sanityCsSettings {
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`

const CaseStudyIndex = props => {
  const caseStudies = props.data.allSanityCaseStudy.edges
  const { currentPage, caseStudyPages } = props.pageContext
  const seo = props.data.sanityCsSettings?.seo
  const customTitle = seo?.metaTitle || null
  const description = seo?.description || null
  const image = seo?.openGraphImage?.asset?.url || null

  return (
    <Layout theme="light">
      <SEO
        title="Watershed Case Studies"
        customTitle={customTitle}
        description={description}
        image={image}
      />
      <Hero heroTitle="Watershed Case Studies" theme="light" graph="radar" />
      <PostWrapper>
        <PostList
          items={caseStudies}
          theme="brandDelta"
          type="case-studies"
          label="Read More"
          alt
        />
        <PostSidebar theme="brandDelta" type="caseStudies" />
      </PostWrapper>
      {caseStudyPages > 1 && (
        <Pagination
          base="case-studies/"
          currentPage={currentPage}
          totalPages={caseStudyPages}
        />
      )}
    </Layout>
  )
}

export default CaseStudyIndex
